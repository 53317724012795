@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800;900&family=Rubik:wght@400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
#hiddenInput {
  display: none !important;
}
#editTextButton {
  display: none !important;
}

.ActionStart {
  background: linear-gradient(197deg, #f17cd0 -51.02%, #6749cd 88.98%);
  filter: drop-shadow(0px 8px 16px rgba(241, 124, 208, 0.1));
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  display: none;
}

.stocastic-modal::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  display: block;
 
}

::-webkit-overflow-scrolling {
  touch-action: pan-y;
}
::-webkit-scrollbar-thumb {
  background-color: #53535365;
  border-radius: 10px;
  margin-right: 5px;
}
.glass {
  background: rgba(255, 255, 255, 0.6);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.9px);
  -webkit-backdrop-filter: blur(5.9px);
  border: 1px solid rgba(255, 255, 255, 0.52);
}
.touch-scroll {
  -webkit-overflow-scrolling: touch !important;
}

.highlightHTML a {
  background-color: #0c56df;
  color: white;
  padding: 2px;
  border-radius: 5px;
  overflow-wrap: break-word !important;
  word-break: break-all !important;
}
.highlightHTML {
  color: black !important;
}

.hearts {
  position: absolute;
  bottom: 18%;
  left: 10%;
}
.hearts .heart {
  position: absolute;
  list-style: none;
  width: 32px;
  height: 32px;
  margin: 4px;
  opacity: 0;
}
.hearts .heart:before,
.hearts .heart:after {
  position: absolute;
  content: "";
  left: 16px;
  top: 0;
  width: 16px;
  height: 24px;
  background: linear-gradient(197deg, #ff5656 100%, #fe6c6c 100%);
  -moz-border-radius: 16px 16px 0 0;
  -o-border-radius: 16px 16px 0 0;
  border-radius: 16px 16px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.hearts .heart:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.hearts.flying > .heart:nth-child(even) {
  -webkit-animation: 3.8s flyingEven linear infinite;
  -moz-animation: 2.8s flyingEven linear infinite;
  animation: 2.8s flyingEven linear infinite;
}
.hearts.flying > .heart:nth-child(odd) {
  -webkit-animation: 3.8s flyingOdd linear infinite;
  -moz-animation: 2.8s flyingOdd linear infinite;
  animation: 2.8s flyingOdd linear infinite;
}
.hearts.flying > .heart:nth-child(1) {
  animation-delay: 0.2s;
}
.hearts.flying > .heart:nth-child(2) {
  animation-delay: 0.3s;
}
.hearts.flying > .heart:nth-child(3) {
  animation-delay: 0.6s;
}
.hearts.flying > .heart:nth-child(4) {
  animation-delay: 1.5s;
}
.hearts.flying > .heart:nth-child(5) {
  animation-delay: 2.2s;
}
.hearts.flying > .heart:nth-child(6) {
  animation-delay: 2.9s;
}
.hearts.flying > .heart:nth-child(7) {
  animation-delay: 3.2s;
}
.hearts.flying > .heart:nth-child(8) {
  animation-delay: 3.5s;
}
.hearts.flying > .heart:nth-child(9) {
  animation-delay: 3.8s;
}
.hearts.flying > .heart:nth-child(10) {
  animation-delay: 4.1s;
}
.hearts.flying > .heart:nth-child(11) {
  animation-delay: 4.3s;
}
.hearts.flying > .heart:nth-child(12) {
  animation-delay: 5s;
}
@-moz-keyframes flyingEven {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    transform: translate(35px, 0px) scale(1.5);
    opacity: 0;
    filter: blur(5px);
  }
}
@-webkit-keyframes flyingEven {
  0% {
    transform: translate(0px, 0px) scale(1);
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    transform: translate(0px, -500px) scale(1);
    opacity: 0;
    filter: blur(5px);
  }
}
@keyframes flyingEven {
  0% {
    transform: translate(5px, 10px) scale(1);
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    transform: translate(0px, -480px) scale(1.5);
    opacity: 0;
    filter: blur(5px);
  }
}
@-moz-keyframes flyingOdd {
  0% {
    transform: translate(5px, 15px) scale(1);
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    transform: translate(10px, -480px) scale(1.5);
    opacity: 0;
    filter: blur(5px);
  }
}
@-webkit-keyframes flyingOdd {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    transform: translate(-35px, -480px) scale(1.5);
    opacity: 0;
    filter: blur(5px);
  }
}
@keyframes flyingOdd {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    transform: translate(-60px, -480px) scale(1.5);
    opacity: 0;
    filter: blur(5px);
  }
}

.selected--avatar::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #7f44d6 99.99%,
    #666666 100%
  );
  border-radius: 1rem;
}

.otp--input input::-webkit-outer-spin-button,
.otp--input input::-webkit-inner-spin-button {
  /* Chrome, Safari, Edge, Opera */

  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#streamingVideo {
  background-color: #000;


}
.MuiInput-root {
  height: 50px !important;
  color: white !important;
}
.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.log-out-button {
  position: absolute;
  right: 35px;
  top: 16px;
  width: 50px;
  height: 50px;
  background-color: #a578e7;
  border-radius: 5px;
  color: #ffffff;
}

@media (max-width: 667px) {
  .log-out-button {
    top: auto;
    bottom: 30px !important;
  }
}
@media (max-width: 1024px) {
  #streamingVideo {
    background-color: #000;
    border-radius: 0px;
  
  }
}

