@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.background-gradient {
  background-image: linear-gradient(
    to right bottom,
    #7f44d6,
    #7b40d4,
    #773cd2,
    #7338d0,
    #6f34ce,
    #7536ce,
    #7a37cd,
    #7f39cd,
    #8e41ce,
    #9b4ad0,
    #a753d1,
    #b35dd3
  );
}

.pulse {
  position: relative;
  height: 112px;
  width: 112px;
}

.pulse:before,
.pulse:after {
  border-radius: 50%;
  content: "";
  position: absolute;
}

.pulse:before {
  background: #ffffff;
  height: 22.4px;
  width: 22.4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pulse:after {
  animation: pulse-t3pv1p 1.2000000000000002s infinite;
  border: 11.2px solid #ffffff;
  height: 100%;
  width: 100%;
}

@keyframes pulse-t3pv1p {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(1);
  }
}

.small_pulse {
  position: relative;
  height: 40px;
  width: 40px;
}

.small_pulse:before,
.small_pulse:after {
  border-radius: 50%;
  content: "";
  position: absolute;
}

.small_pulse:before {
  background: #ffffff;
  height: 12px;
  width: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.small_pulse:after {
  animation: pulse-t3pv1p 2s infinite;
  border: 11.2px solid #ffffff;
  height: 100%;
  width: 100%;
  left: 0;
}

